import httpClient from "./httpClient";

import AtaTmpPat from "@/model/ataTmpPat";

export default class AtaTmpPatService {
  static async postPatient(patient: AtaTmpPat) {
    return (await httpClient).post(`/ataTmpPat/`, patient);
  }

  static async getPatientsPage(
    size: number,
    page: number,
    filter = "",
    sort = "",
    params = {}
  ) {
    return (await httpClient).page<AtaTmpPat>(
      "/ataTmpPats-page",
      size,
      page,
      filter,
      sort,
      params
    );
  }
}
