import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d70d87c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = { class: "p-col-fixed" }
const _hoisted_4 = { class: "p-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoLogout = _resolveComponent("AutoLogout")!
  const _component_HeaderToolbar = _resolveComponent("HeaderToolbar")!
  const _component_Navigation = _resolveComponent("Navigation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AutoLogout),
    _createVNode(_component_HeaderToolbar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Navigation)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ]))
}