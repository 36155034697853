
import { Options, Vue } from "vue-class-component";
import Dropdown from "primevue/dropdown";
import FormSpecDef from "@/model/forms/formSpecDef";
import FormSpecification from "@/model/forms/formSpecification";
import { namespace } from "vuex-class";
const formProvider = namespace("formProvider");

@Options({
  components: { Dropdown },
  props: {
    formDef: Object as () => FormSpecDef,
  },
})
export default class DSelect extends Vue {
  formDef!: FormSpecDef;
  @formProvider.State
  formSpec!: FormSpecification;
}
