import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-float-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_ctx.formDef.name)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.formSpec.formData[_ctx.formDef.name],
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formSpec.formData[_ctx.formDef.name]) = $event)),
          options: _ctx.formDef.options,
          optionLabel: "label",
          optionValue: "value",
          disabled: _ctx.formDef.disabled
        }, null, 8, ["modelValue", "options", "disabled"]),
        _createElementVNode("label", null, _toDisplayString(_ctx.formDef.label), 1)
      ]))
    : _createCommentVNode("", true)
}