
import { Options, Vue } from "vue-class-component";
import FormSpecDef from "@/model/forms/formSpecDef";
import components from "@/components/dynamic/components";

@Options({
  components,
  props: {
    formDef: Object as () => FormSpecDef,
  },
})
export default class DField extends Vue {
  formDef!: FormSpecDef;
}
