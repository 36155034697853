
import { Options, Vue } from "vue-class-component";
import AccessRights from "@/model/enums/accessRights";
import { namespace } from "vuex-class";
const navMenu = namespace("navMenu");

@Options({
  watch: {
    visible: {
      handler: "toggle",
    },
  },
})
export default class Navigation extends Vue {
  declare $refs: {
    nav: HTMLFormElement;
  };
  @navMenu.State
  visible!: boolean;

  accessRights: AccessRights[] = [
    AccessRights.PATIENT_STACK,
    // AccessRights.ATA_REGISTRY
  ]; //TODO From vuex
  private modules: {
    name: string;
    toName: string;
    acces: AccessRights | boolean;
  }[] = [
    { name: "home", toName: "dashboard", acces: true },
    {
      name: "patients",
      toName: "patient-list",
      acces: AccessRights.PATIENT_STACK,
    },
    { name: "register", toName: "test", acces: AccessRights.ATA_REGISTRY },
    { name: "institutions", toName: "dashboard", acces: false },
    { name: "modulegroup", toName: "dashboard", acces: false },
    { name: "users", toName: "dashboard", acces: AccessRights.ATA_USER },
    { name: "rolegroup", toName: "dashboard", acces: false },
    {
      name: "databasestatus",
      toName: "dashboard",
      acces: AccessRights.DATABASE_ADMIN,
    },
    { name: "donors", toName: "dashboard", acces: AccessRights.DONOR_STACK },
    { name: "import", toName: "dashboard", acces: AccessRights.IMPORTS },
    { name: "importhistory", toName: "dashboard", acces: AccessRights.IMPORTS },
    { name: "export", toName: "dashboard", acces: AccessRights.EXPORTS },
    { name: "exporthistory", toName: "dashboard", acces: AccessRights.EXPORTS },
    {
      name: "searchhistory",
      toName: "dashboard",
      acces: AccessRights.SEARCH_COMMANDS,
    },
    { name: "notifications", toName: "dashboard", acces: false },
  ];

  mounted() {
    this.toggle();
  }

  //TODO
  get accesed() {
    return this.modules.filter((module) => {
      return (
        module.acces === true ||
        (module.acces !== false && this.accessRights.includes(module.acces))
      );
    });
  }

  capitalize(t: string) {
    return t.charAt(0).toUpperCase() + t.slice(1);
  }

  toggle() {
    if (this.visible) {
      this.$refs.nav.style.width = "200px";
    } else {
      this.$refs.nav.style.width = "0";
    }
  }
}
