enum PatientMode {
  NONE,
  NEW,
  EDIT_CONFIRM,
  EDIT,
  OTHER_TC,
  DUPLICATE,
}

export default PatientMode;
