import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-float-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_Calendar, {
      modelValue: _ctx.date,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
      disabled: _ctx.formDef.disabled
    }, null, 8, ["modelValue", "disabled"]),
    _createElementVNode("label", null, _toDisplayString(_ctx.formDef.label), 1)
  ]))
}