import httpClient from "./httpClient";
import { AxiosResponse } from "axios";

import AtaTmpPat from "@/model/ataTmpPat";

export default class Bmdpat1Service {
  static async getPatientsPage(
    size: number,
    page: number,
    filter = "",
    sort = "",
    params = {}
  ) {
    return (await httpClient).page<AtaTmpPat>(
      "/bmdpat1s-page",
      size,
      page,
      filter,
      sort,
      params
    );
  }

  static async bmdpatexistPersNumber(
    persnumber: string
  ): Promise<AxiosResponse<AtaTmpPat>> {
    return (await httpClient).get(`/bmdpatexist/${persnumber}`);
  }

  static async bmdpatExistTNumber(
    patientid1: string
  ): Promise<AxiosResponse<AtaTmpPat>> {
    return (await httpClient).get(`/bmdpatexistbypatientid1/${patientid1}`);
  }

  static async favorite(personalnumber: string, favorite: boolean) {
    return (await httpClient).put<void>(
      `/bmdpat1-favorite/${personalnumber}/${favorite}`
    );
  }
}
