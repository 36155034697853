import httpClient from "./httpClient";
import { AxiosResponse } from "axios";
import DnaValidation from "@/model/dnaValidation";

export default class DnaValidationService {
  static async validDna(
    dna: DnaValidation
  ): Promise<AxiosResponse<DnaValidation>> {
    return (await httpClient).post(`/validDna/`, dna);
  }
}
