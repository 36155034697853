import AtaTmpPat from "@/model/ataTmpPat";
import Abo from "@/model/enums/abo";
import Cmv from "@/model/enums/cmv";
import Dispha from "@/model/enums/dispha";
import Ethnicity from "@/model/enums/ethnicity";
import Gender from "@/model/enums/gender";
import Rh from "@/model/enums/rh";
import SearchScope from "@/model/enums/searchScope";
import Status from "@/model/enums/status";

export default class AtaTmpPatUtil {
  static readonly NULLDATE = new Date("1899-12-30T00:00:00.000Z");

  //TODO Move to some more generic util
  static parseDate(date: Date | string | undefined) {
    if (!date) return undefined;
    if (
      typeof date === "string" &&
      (/^\d{1,2}\/\d{1,2}\/\d{4}$/g.test(date) ||
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/g.test(date))
    ) {
      const d = new Date(date);
      if (!isNaN(d.getDay())) date = d;
    }
    if (
      date instanceof Date &&
      date.getTime() == AtaTmpPatUtil.NULLDATE.getTime()
    )
      return undefined;
    if (typeof date === "string") return undefined;
    return date;
  }

  static getEmpty(): AtaTmpPat {
    return {
      id: undefined,
      patientid: "",
      lastname: "",
      firstname: "",
      birthname: "",
      personalnumber: "",
      birthdate: undefined,
      ethnicity: +Ethnicity.UK.code,
      gender: +Gender.UNKNOWN.code,
      weight: 0,
      height: 0,
      abo: Abo.UNKNOWN.code + "",
      rh: Rh.UNKNOWN.code + "",
      dgemdis: 0,
      dateofdg: undefined,
      physician: "",
      diseasephase: +Dispha.NA.code,
      cmv: +Cmv.UNKNOWN.code,
      status: +Status.UNKNOWN.code,
      statusdate: undefined,
      comment: "",
      a1: "",
      b1: "",
      c1: "",
      drb11: "",
      drb31: "",
      drb41: "",
      drb51: "",
      dqb11: "",
      dqa11: "",
      dpb11: "",
      dpa11: "",
      a2: "",
      b2: "",
      c2: "",
      drb12: "",
      drb32: "",
      drb42: "",
      drb52: "",
      dqb12: "",
      dqa12: "",
      dpb12: "",
      dpa12: "",
      datetestdna: undefined,
      dnalaboratory: "",
      userid: 0,
      patcenter: "",
      patientid1: "",
      searchscope: +SearchScope.UNKNOWN.code,
      news: undefined,
      dgtext: "",
      consent: 0,
      favorite: false,
    };
  }
}
