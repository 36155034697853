import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import router from "@/router";
import AtaTmpPatService from "@/service/ataTmpPat";
import AtaTmpPat from "@/model/ataTmpPat";
import PatientMode from "@/util/enums/patientMode";
import DnaValidationService from "@/service/dnaValidation";
import DnaValidation from "@/model/dnaValidation";
import Alert from "@/util/alert";
import AtaTmpPatUtil from "@/util/ataTmpPatUtil";
import Bmdpat1Service from "@/service/bmdpat1";
import Page from "@/model/page";

@Module({ namespaced: true })
export default class Patient extends VuexModule {
  private tmpSelect = {} as AtaTmpPat;
  patients: AtaTmpPat[] = [];
  ataPatients: AtaTmpPat[] = [];
  patientsCount = 0;
  ataPatientsCount = 0;
  selected = {} as AtaTmpPat;
  validationMap: Map<string, string[]> = new Map();
  alerts: Alert[] = [];
  mode: PatientMode = PatientMode.NONE;
  errorWhilePosting = false;
  rows = 5;

  @Mutation
  SET_PATIENTS(patients: Page<AtaTmpPat>) {
    this.patients = patients.content;
    this.patientsCount = patients.totalElements;
    // console.log(patients);
  }

  @Mutation
  SET_ATA_PATIENTS(patients: Page<AtaTmpPat>) {
    this.ataPatients = patients.content;
    this.ataPatientsCount = patients.totalElements;
    // console.log(patients);
  }

  @Mutation
  SET_SELECTED(patient: AtaTmpPat) {
    this.selected = patient;
    this.alerts = [];
    this.validationMap.clear();
    this.mode = PatientMode.NONE;
  }

  @Mutation
  SET_TMP(patient: AtaTmpPat) {
    this.tmpSelect = patient;
    this.alerts = [];
    this.validationMap.clear();
    this.mode = PatientMode.EDIT_CONFIRM;
  }

  @Mutation
  SET_MODE(mode: PatientMode) {
    if (this.mode == PatientMode.EDIT_CONFIRM && mode == PatientMode.EDIT) {
      this.selected = this.tmpSelect;
    }
    this.mode = mode;
  }

  @Mutation
  SET_ROWS(rows: number) {
    this.rows = rows;
  }

  @Mutation
  ADD_ERROR({ field, msg }: { field: string; msg: string }) {
    if (!this.validationMap.get(field)) this.validationMap.set(field, []);
    this.validationMap.get(field)?.push(msg);
    // console.log(this.validationMap);
  }

  @Mutation
  RESET_ALERT() {
    this.alerts = [];
  }

  @Mutation
  RESET_ERROR() {
    this.validationMap.clear();
  }

  @Mutation
  GEN_ALERTS() {
    this.alerts = [];
    this.validationMap.forEach((value, key) => {
      value.forEach((msg) => this.alerts.push(new Alert(key, msg)));
    });
    // console.log(this.alerts);
  }

  @Mutation
  SET_ERROR_WHILE_POSTING(value: boolean) {
    this.errorWhilePosting = value;
  }

  @Action
  async fetchPatients({
    size,
    page,
    filter,
    sort,
    params,
  }: {
    size: number;
    page: number;
    filter?: string;
    sort?: string;
    params?: Record<string, unknown>;
  }) {
    await Bmdpat1Service.getPatientsPage(size, page, filter, sort, params)
      .then((resp) => {
        this.context.commit("SET_PATIENTS", resp.data);
      })
      .catch((err) => console.error(err));
  }

  @Action
  async fetchAtaPatients({
    size,
    page,
    filter,
    sort,
    params,
  }: {
    size: number;
    page: number;
    filter?: string;
    sort?: string;
    params?: Record<string, unknown>;
  }) {
    await AtaTmpPatService.getPatientsPage(size, page, filter, sort, params)
      .then((resp) => {
        this.context.commit("SET_ATA_PATIENTS", resp.data);
      })
      .catch((err) => console.error(err));
  }

  @Action
  async toggleFavorite(patient: AtaTmpPat) {
    await Bmdpat1Service.favorite(
      patient.personalnumber,
      !patient.favorite
    ).then(() => {
      patient.favorite = !patient.favorite;
    });
  }

  @Action
  async postPatients(patient: AtaTmpPat) {
    this.context.commit("SET_ERROR_WHILE_POSTING", false);
    await AtaTmpPatService.postPatient(patient)
      .then(() => console.log("Saved"))
      .catch((err) => {
        if (err.response.status === 400) {
          // console.log(err.response);
          if (err.response.data.errors) {
            err.response.data.errors.forEach(
              (e: { field: string; defaultMessage: string }) => {
                this.context.commit("ADD_ERROR", {
                  field: e.field,
                  msg: e.defaultMessage,
                });
              }
            );
          } else {
            this.context.commit("ADD_ERROR", {
              field: "",
              msg:
                typeof err.response.data == "string"
                  ? err.response.data
                  : JSON.stringify(err.response.data),
            });
          }
        } else {
          console.error(err);
          this.context.commit("SET_ERROR_WHILE_POSTING", true);
        }
      });
  }

  @Action
  async patientExists(personalnumber: string) {
    await Bmdpat1Service.bmdpatexistPersNumber(personalnumber)
      .then((data) => {
        // console.log(data);
        if (data.data) {
          this.context.commit("SET_TMP", data.data);
          // this.context.commit("SET_MODE", PatientMode.EDIT_CONFIRM);
        } else {
          this.context.commit("SET_MODE", PatientMode.NEW);
        }
      })
      .catch((err) => {
        // console.log(err);
        if (err.response.status === 409) {
          this.context.commit("SET_MODE", PatientMode.OTHER_TC);
        } else if (err.response.status === 406) {
          this.context.commit("SET_MODE", PatientMode.DUPLICATE);
        }
      });
  }

  @Action
  selectPat(patient: AtaTmpPat) {
    this.context.commit("SET_SELECTED", patient);
    this.context.commit("SET_MODE", PatientMode.EDIT);
    this.context.commit("SET_ERROR_WHILE_POSTING", false);
    router.push({ name: "add-patient" });
  }

  @Action
  async save() {
    if (this.validationMap.size > 0) {
      this.context.commit("GEN_ALERTS");
      return;
    }
    await this.context.dispatch("postPatients", this.selected);
    if (this.validationMap.size > 0) {
      this.context.commit("GEN_ALERTS");
    } else {
      this.context.commit("SET_SELECTED", {});
      router.back();
    }
  }

  @Action
  async dnaValidation(dna: DnaValidation) {
    await DnaValidationService.validDna(dna)
      .then()
      .catch((err) => {
        if (err.response.status === 400) {
          // console.log(err.response.data.errors);
          err.response.data.errors.forEach(
            (e: { field: string; defaultMessage: string }) => {
              this.context.commit("ADD_ERROR", {
                field: e.field,
                msg: e.defaultMessage,
              });
            }
          );
        } else {
          console.error(err);
        }
      });
  }

  @Action
  addPatient() {
    this.context.dispatch("selectPat", AtaTmpPatUtil.getEmpty());
    this.context.commit("SET_MODE", PatientMode.NONE);
  }
}
