import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-float-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.formDef.name)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _withDirectives(_createVNode(_component_InputText, {
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          disabled: _ctx.formDef.disabled,
          class: _normalizeClass({ 'p-invalid': _ctx.formError[_ctx.formDef.name] }),
          type: "text"
        }, null, 8, ["modelValue", "disabled", "class"]), [
          [
            _directive_tooltip,
            _ctx.formError[_ctx.formDef.name],
            void 0,
            { focus: true }
          ]
        ]),
        _createElementVNode("label", null, _toDisplayString(_ctx.formDef.label), 1)
      ]))
    : _createCommentVNode("", true)
}