
import { Options, Vue } from "vue-class-component";
import FormSpecDef from "@/model/forms/formSpecDef";
import { namespace } from "vuex-class";
const formProvider = namespace("formProvider");

@Options({
  props: {
    formDef: Object as () => FormSpecDef,
  },
})
export default class DButton extends Vue {
  formDef!: FormSpecDef;

  @formProvider.Action
  button!: (action: string) => void;
}
