import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6378ed42"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "menu",
  ref: "nav"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accesed, (module, i) => {
        return (_openBlock(), _createElementBlock("li", {
          key: i,
          class: _normalizeClass(module.name.toLowerCase())
        }, [
          _createVNode(_component_router_link, {
            to: { name: module.toName },
            class: "icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.capitalize(module.name)), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ], 2))
      }), 128))
    ])
  ], 512))
}