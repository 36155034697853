import Page from "@/model/page";
import axios from "axios";
import { getConfig } from "@/config";
import AuthService from "./auth";
import router from "@/router";

async function configure() {
  const config = await getConfig();
  const httpClient = {
    ...axios.create({
      baseURL: config.url,
      timeout: 10 * 1000,
      withCredentials: true,
    }),
    page: <T>(
      url: string,
      size: number,
      page: number,
      filter = "",
      sort = "",
      params: Record<string, unknown> = {}
    ) => {
      let query = `${url}?page=${page}&size=${size}`;
      if (filter.length > 0) query += `&filter=${filter}`;
      if (sort.length > 0) query += `&order=${sort}`;
      for (const p in params) query += `&${p}=${params[p]}`;
      return httpClient.get<Page<T>>(query);
    },
  };

  // const authInterceptor = (config: AxiosRequestConfig) => {
  //   config.headers["Authorization"] = localStorage.getItem("user");
  //   return config;
  // };
  // httpClient.interceptors.request.use(authInterceptor);

  httpClient.interceptors.response.use(
    (r) => r,
    (r) => {
      // console.log("Rejected: " + JSON.stringify(r.config));
      if (!r.response && r.message === "Network Error") {
        //Redirect if cant reach BE
        console.error("HttpClient | No BE");
        router.push("/login-form");
      }
      if (
        [401, 403].includes(r.response.status) &&
        !["/sign", "/authentication"].includes(r.config.url)
      ) {
        console.log("HttpClient | Unauthorized -> Logging out");
        AuthService.logout();
      }
      throw r;
    }
  );

  // httpClient.interceptors.response.use(undefined, err => {
  //   console.log("interceptors: " + err);
  //   return Promise.reject(err);
  // });

  return httpClient;
}

export default configure();
