
import { Options, Vue } from "vue-class-component";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import FormSpecDef from "@/model/forms/formSpecDef";
import FormSpecification from "@/model/forms/formSpecification";
import { namespace } from "vuex-class";
const formProvider = namespace("formProvider");

@Options({
  components: {
    TabView,
    TabPanel,
  },
  props: {
    formDef: Object as () => FormSpecDef,
  },
})
export default class DTabs extends Vue {
  formDef!: FormSpecDef;
  @formProvider.State
  formSpec!: FormSpecification;
}
