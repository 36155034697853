import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";
import SimpleLayout from "@/layout/SimpleLayout.vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { Ping } from "./service/ping";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    alias: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "./views/Dashboard.vue"),
  },
  {
    path: "/login-form",
    name: "login-form",
    meta: { layout: SimpleLayout, requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: "login" */ "./views/LoginForm.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import(/* webpackChunkName: "test" */ "./views/Test.vue"),
  },
  {
    path: "/patient-list",
    name: "patient-list",
    component: () =>
      import(/* webpackChunkName: "patientList" */ "./views/PatientList.vue"),
  },
  {
    path: "/add-patient",
    name: "add-patient",
    component: () =>
      import(/* webpackChunkName: "addPatient" */ "./views/AddPatient.vue"),
  },
  {
    path: "/recommended-donors",
    name: "recommended-donors",
    component: () =>
      import(
        /* webpackChunkName: "recommendedDonors" */ "./views/RecommendedDonors.vue"
      ),
  },
  {
    path: "/user-settings",
    name: "user-settings",
    component: () =>
      import(/* webpackChunkName: "userSettings" */ "./views/UserSettings.vue"),
  },
  {
    path: "/form-test",
    name: "form-test",
    component: () =>
      import(/* webpackChunkName: "formTest" */ "./views/DFormsTest.vue"),
  },
];

for (const route of routes) {
  if (!route.meta) route.meta = {};
  if (!route.meta.layout) route.meta.layout = DefaultLayout;
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.path != "/login-form") await Ping.ping(); //Check be (axios will redirect on error)
  return next();
});

// ? Old setting, just for inspiration
// router.beforeEach(async (to, from, next) => {
// redirect to login page if not logged in and trying to access a restricted page
// const publicPages = ["/login-form"];
// const authRequired = !publicPages.includes(to.path);
// const loggedIn = localStorage.getItem("user");
// const TwoFA = localStorage.getItem("code") == "ok";
// const config = await getConfig();

// if (authRequired && !loggedIn) {
//   return next({
//     path: "/login-form",
//     query: { returnUrl: to.path },
//   });
// }

// if (
//   to.path != "/user-settings" &&
//   authRequired &&
//   loggedIn &&
//   !TwoFA &&
//   !config.secret &&
//   !config.no2fa
// )
//   return next({
//     path: "/user-settings",
//   });

//   next();
// });

export default router;
