// import EnumValue from ".";

import Enum from "@/util/enum";
import EnumValue from ".";

export default class Dispha extends Enum {
  static PI: EnumValue = { name: "Primary Induction Therapy", code: 1 };
  static PF: EnumValue = { name: "Primary Induction Failure", code: 2 };
  static C0: EnumValue = { name: "0. Complete Remission", code: 3 };
  static C1: EnumValue = { name: "1. Complete Remission", code: 4 };
  static C2: EnumValue = { name: "2. Complete Remission", code: 5 };
  static C3: EnumValue = { name: "3. Complete Remission", code: 6 };
  static C4: EnumValue = { name: "4. Complete Remission", code: 7 };
  static C5: EnumValue = { name: "5. Complete Remission", code: 8 };
  static C6: EnumValue = { name: "6. Complete Remission", code: 9 };
  static C7: EnumValue = { name: "7. Complete Remission", code: 10 };
  static C8: EnumValue = { name: "8. Complete Remission", code: 11 };
  static C9: EnumValue = { name: "9. Complete Remission", code: 12 };
  static N0: EnumValue = { name: "0. Chronic Phase", code: 13 };
  static N1: EnumValue = { name: "1. Chronic Phase", code: 14 };
  static N2: EnumValue = { name: "2. Chronic Phase", code: 15 };
  static N3: EnumValue = { name: "3. Chronic Phase", code: 16 };
  static N4: EnumValue = { name: "4. Chronic Phase", code: 17 };
  static N5: EnumValue = { name: "5. Chronic Phase", code: 18 };
  static N6: EnumValue = { name: "6. Chronic Phase", code: 19 };
  static N7: EnumValue = { name: "7. Chronic Phase", code: 20 };
  static N8: EnumValue = { name: "8. Chronic Phase", code: 21 };
  static N9: EnumValue = { name: "9. Chronic Phase", code: 22 };
  static P0: EnumValue = { name: "0. Partial Remission", code: 23 };
  static P1: EnumValue = { name: "1. Partial Remission", code: 24 };
  static P2: EnumValue = { name: "2. Partial Remission", code: 25 };
  static P3: EnumValue = { name: "3. Partial Remission", code: 26 };
  static P4: EnumValue = { name: "4. Partial Remission", code: 27 };
  static P5: EnumValue = { name: "5. Partial Remission", code: 28 };
  static P6: EnumValue = { name: "6. Partial Remission", code: 29 };
  static P7: EnumValue = { name: "7. Partial Remission", code: 30 };
  static P8: EnumValue = { name: "8. Partial Remission", code: 31 };
  static P9: EnumValue = { name: "9. Partial Remission", code: 32 };
  static R0: EnumValue = { name: "0. Relapse", code: 33 };
  static R1: EnumValue = { name: "1. Relapse", code: 34 };
  static R2: EnumValue = { name: "2. Relapse", code: 35 };
  static R3: EnumValue = { name: "3. Relapse", code: 36 };
  static R4: EnumValue = { name: "4. Relapse", code: 37 };
  static R5: EnumValue = { name: "5. Relapse", code: 38 };
  static R6: EnumValue = { name: "6. Relapse", code: 39 };
  static R7: EnumValue = { name: "7. Relapse", code: 40 };
  static R8: EnumValue = { name: "8. Relapse", code: 41 };
  static R9: EnumValue = { name: "9. Relapse", code: 42 };
  static AP: EnumValue = { name: "Accelerated Phase", code: 43 };
  static BC: EnumValue = { name: "Blast Crisis", code: 44 };
  static AD: EnumValue = { name: "Advanced Disease", code: 45 };
  static SD: EnumValue = { name: "Stable Disease", code: 46 };
  static RD: EnumValue = { name: "Refractory Disease", code: 47 };
  static NA: EnumValue = { name: "Not Applicable", code: 48 };
}

/*
PI = Primary Induction Therapy
PF = Primary Induction Failure
Cn = n-th Complete Remission
Nn = n-th Chronic Phase
Pn = n-th Partial Remission
Rn = n-th Relapse
AP = Accelerated Phase
BC = Blast Crisis
AD = Advanced Disease
SD = Stable Disease
RD = Refractory Diseas
RD = Refractory Disease
NA = Not Applicable
*/
