import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    disabled: _ctx.formDef.disabled,
    label: _ctx.formDef.label,
    class: "p-button-rounded p-button-success",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.button(_ctx.formDef.action ? _ctx.formDef.action : '')))
  }, null, 8, ["disabled", "label"]))
}