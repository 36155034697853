import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-grid p-formgrid p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DField = _resolveComponent("DField", true)!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('p-mb-4 p-col-12 p-xl-' + _ctx.formDef.size)
  }, [
    (_ctx.formDef.children && _ctx.formDef.children.length)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.formDef.type), {
          key: 0,
          formDef: _ctx.formDef
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formDef.children, (field, i) => {
                return (_openBlock(), _createBlock(_component_DField, {
                  formDef: field,
                  key: i
                }, null, 8, ["formDef"]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["formDef"]))
      : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.formDef.type), {
          key: 1,
          formDef: _ctx.formDef
        }, null, 8, ["formDef"]))
  ], 2))
}