import httpClient from "./httpClient";
import { AxiosResponse } from "axios";

import AtaEmdReq from "@/model/ataEmdReq";

export default class AtaEmdReqService {
  static async getEmdReqs(
    patientId: string,
    donorGrid: string
  ): Promise<AxiosResponse<AtaEmdReq[]>> {
    return (await httpClient).get(`/ateEmdReqs/${patientId}/${donorGrid}`);
  }

  static async postEmdReq(
    request: AtaEmdReq
  ): Promise<AxiosResponse<AtaEmdReq>> {
    return (await httpClient).post(`/ataEmdReq/`, request);
  }

  static async getEmdReqSample(
    patientId: string,
    donorGrid: string
  ): Promise<AxiosResponse<AtaEmdReq>> {
    return (await httpClient).get(
      `/ateEmdReqs/sample/${patientId}/${donorGrid}`
    );
  }
}
