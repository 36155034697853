import Enum from "@/util/enum";
import EnumValue from ".";

export default class Abo extends Enum {
  static ZERO: EnumValue = { name: "0", code: "0" };
  static A: EnumValue = { name: "A", code: "A" };
  static B: EnumValue = { name: "B", code: "B" };
  static AB: EnumValue = { name: "AB", code: "AB" };
  static UNKNOWN: EnumValue = { name: "Unknown", code: "?" };
}
