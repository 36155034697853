import httpClient from "./httpClient";
import { AxiosResponse } from "axios";

import RecommendedDonor from "@/model/recommendedDonor";
import MoreDonorResponse from "@/model/moreDonorResponse";

export default class RecommendedDonorsService {
  static async getEmddon1(
    patientid: string
  ): Promise<AxiosResponse<RecommendedDonor[]>> {
    return (await httpClient).get(`/redommended/${patientid}`);
  }

  static async moreDonorList(
    patientnum: string
  ): Promise<AxiosResponse<MoreDonorResponse[]>> {
    return (await httpClient).get(`/moreDonorList/${patientnum}`);
  }

  static async moreDonor(id: number): Promise<AxiosResponse<string>> {
    return (await httpClient).get(`/moreDonor/${id}`, {
      responseType: "blob",
    });
  }
}
