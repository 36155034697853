import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import User from "@/model/user";

// ! Not used anywhere atm
@Module({ namespaced: true })
export default class CurrentUser extends VuexModule {
  user!: User;
  // roles!: Roles;

  @Mutation
  SET_USER(user: User) {
    this.user = user;
  }

  // @Mutation
  // SET_ROLES(roles: Roles) {
  //   this.roles = roles;
  // }
}
