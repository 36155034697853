import Enum from "@/util/enum";
import EnumValue from ".";

export default class Status extends Enum {
  static UNKNOWN: EnumValue = { name: "Not specified", code: 0 };
  static ATX: EnumValue = { name: "Autologous Transplantation", code: 1 };
  static RMT: EnumValue = {
    name: "BMSC Transplantation with related donor",
    code: 2,
  };
  static UMT: EnumValue = {
    name: "BMSC Transplantation with unrelated donor",
    code: 3,
  };
  static RCT: EnumValue = {
    name: "CBSC Transplantation with related donor",
    code: 4,
  };
  static UCT: EnumValue = {
    name: "CBSC Transplantation with unrelated donor",
    code: 5,
  };
  static RPT: EnumValue = {
    name: "PBSC Transplantation with related donor",
    code: 6,
  };
  static UPT: EnumValue = {
    name: "PBSC Transplantation with unrelated donor",
    code: 7,
  };
  static GCC: EnumValue = {
    name: "Good clinical condition of patient (i.e. remission)",
    code: 8,
  };
  static BCC: EnumValue = {
    name: "Clinical condition of patient deteriorated (i.e. relapse)",
    code: 9,
  };
  static PDC: EnumValue = {
    name: "Patient deceased before transplantation",
    code: 10,
  };
  static PAT: EnumValue = {
    name: "Patient responds to alternative therapy",
    code: 11,
  };
  static PPR: EnumValue = {
    name: "Patient withdrawal (non medical, personal reasons)",
    code: 12,
  };
  static ICH: EnumValue = {
    name: "Transplantation indication changed (i.e. wrong diagnosis)",
    code: 13,
  };
  static FND: EnumValue = { name: "Other / better donor found", code: 14 };
  static NDF: EnumValue = { name: "No suitable donor found", code: 15 };
  static OTH: EnumValue = { name: "Other reason", code: 16 };
  static UNK: EnumValue = { name: "Unknown reason", code: 17 };
  static COR: EnumValue = { name: "Correction of request", code: 18 };
  static HMM: EnumValue = { name: "HLA mismatch", code: 19 };
  static DRR: EnumValue = { name: "Donor related reason", code: 20 };
  static DIE: EnumValue = {
    name: "Patient deceased after transplantation",
    code: 21,
  };
}
