import httpClient from "./httpClient";
import { AxiosResponse } from "axios";
import router from "@/router";
import store from "@/store";

type Auth = {
  username: string;
  password: string;
};
export default class AuthService {
  static async auth(
    auth: Auth | undefined,
    type: number | undefined
  ): Promise<AxiosResponse<Record<string, boolean>>> {
    return (await httpClient).get(
      `/authentication${type == undefined ? "" : "/" + type}`,
      {
        auth,
      }
    );
  }

  static async sign(
    auth: Auth,
    code: number,
    type: number
  ): Promise<AxiosResponse<string>> {
    await this.logout(false).catch(console.log);
    return (await httpClient).get(`/sign`, {
      auth,
      params: {
        code,
        type,
      },
    });
  }

  static async logout(redirect = true): Promise<AxiosResponse<string>> {
    store.commit("ataTmpPat/SET_ROWS", 5);
    if (redirect) router.push("/login-form");
    return (await httpClient).get(`/logout`);
  }

  static async googleAuth(
    auth: Auth | undefined = undefined
  ): Promise<AxiosResponse<string>> {
    return (await httpClient).get(`/twoFactorAuthen`, {
      auth,
      responseType: "arraybuffer",
    });
  }
}
