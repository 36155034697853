import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import router from "@/router";
import AtaTmpPat from "@/model/ataTmpPat";
import RecommendedDonor from "@/model/recommendedDonor";
import MoreDonorResponse from "@/model/moreDonorResponse";
import AtaEmdReq from "@/model/ataEmdReq";

import RecommendedDonorsService from "@/service/recommendedDonors";
import AtaEmdReqService from "@/service/ataEmdReq";
import ReqDonWrapper from "@/util/reqDonWrapper";

@Module({ namespaced: true })
export default class RecommendedDonors extends VuexModule {
  pat = {} as AtaTmpPat;
  donors: ReqDonWrapper[] = [];
  pdfs: MoreDonorResponse[] = [];
  loading = false;

  @Mutation
  SET_LOADING(l: boolean) {
    this.loading = l;
  }

  @Mutation
  SET_PAT(pat: AtaTmpPat) {
    this.donors = [];
    this.pat = pat;
  }

  @Mutation
  SET_DONORS(donors: RecommendedDonor[]) {
    this.donors = donors.map((don) => {
      return { don, req: [] };
    });
    // console.log(donors);
  }

  @Mutation
  SET_PDFS(pdfs: MoreDonorResponse[]) {
    // console.log(pdfs);
    this.pdfs = pdfs;
  }

  @Action
  async showRecommendedDonors(patient: AtaTmpPat) {
    this.context.commit("SET_LOADING", true);
    this.context.commit("SET_PAT", patient);
    router.push({ name: "recommended-donors" });
    // await new Promise(resolve => setTimeout(resolve, 5000));
    await RecommendedDonorsService.getEmddon1(this.pat.patientid)
      .then((resp) => {
        // console.log(resp.data[0]);
        this.context.commit("SET_DONORS", resp.data);
      })
      .catch(() => this.context.commit("SET_DONORS", []));

    await RecommendedDonorsService.moreDonorList(patient.patientid)
      .then((resp) => {
        this.context.commit("SET_PDFS", resp.data);
      })
      .catch(() => this.context.commit("SET_PDFS", []));
    this.context.commit("SET_LOADING", false);
  }

  @Action
  async getMoreDonor(pdf: MoreDonorResponse) {
    await RecommendedDonorsService.moreDonor(pdf.serial).then((resp) => {
      const url = window.URL.createObjectURL(
        new Blob([resp.data]) //, { type: "application/pdf" }
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${this.pat.patientid}_${pdf.filename}`);
      document.body.appendChild(link);
      link.click();
    });
  }

  @Action
  async sendRequest(request: AtaEmdReq) {
    // console.log("Request sent:");
    // console.table(request);
    await AtaEmdReqService.postEmdReq(request)
      // .then(resp => {
      //   // console.log(resp.data);
      // })
      .catch(console.error);
  }

  @Action
  async getRequests(don: ReqDonWrapper) {
    await AtaEmdReqService.getEmdReqs(this.pat.patientid, don.don.grid)
      .then((resp) => {
        // console.log(resp.data);
        don.req = resp.data;
      })
      .catch(console.error);
  }

  @Action
  async getRequestsSample(don: ReqDonWrapper) {
    await AtaEmdReqService.getEmdReqSample(this.pat.patientid, don.don.grid)
      .then((resp) => {
        // console.log(resp.data);
        don.sample = resp.data;
      })
      .catch(console.error);
  }
}
