
import { Options, Vue } from "vue-class-component";
import { getConfig } from "@/config";

@Options({})
export default class App extends Vue {
  version: string = process.env.VUE_APP_VERSION;
  node_env = process.env.NODE_ENV != "production";
  tc = "";
  secret_mode = false;

  async created() {
    const config = await getConfig();
    this.tc = config.country.toUpperCase();
    this.secret_mode = config.secret;
  }
}
