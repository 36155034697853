import Enum from "@/util/enum";
import EnumValue from ".";

class AccessRights extends Enum {
  static DONOR_COMMANDS: EnumValue = { code: "0011", name: "DONOR_COMMANDS" };
  static DONOR_STACK: EnumValue = { code: "0021", name: "DONOR_STACK" };
  static DONOR_REPORTS: EnumValue = { code: "0031", name: "DONOR_REPORTS" };
  static DONOR_ANALYTIC_TOOL: EnumValue = {
    code: "0041",
    name: "DONOR_ANALYTIC_TOOL",
  };
  static PATIENT_COMMANDS: EnumValue = {
    code: "0051",
    name: "PATIENT_COMMANDS",
  };
  static PATIENT_REPORTS: EnumValue = { code: "0061", name: "PATIENT_REPORTS" };
  static PATIENT_STACK: EnumValue = { code: "0071", name: "PATIENT_STACK" };
  static PATIENT_ANALYTIC_TOOL: EnumValue = {
    code: "0081",
    name: "PATIENT_ANALYTIC_TOOL",
  };
  static SEARCH_COMMANDS: EnumValue = { code: "0091", name: "SEARCH_COMMANDS" };
  static EMDIS_COMMANDS: EnumValue = { code: "0101", name: "EMDIS_COMMANDS" };
  static EDITION_COMMANDS: EnumValue = {
    code: "0111",
    name: "EDITION_COMMANDS",
  };
  static DATABASE_ADMIN: EnumValue = { code: "0121", name: "DATABASE_ADMIN" };
  static DONOR_EDITOR: EnumValue = { code: "0131", name: "DONOR_EDITOR" };
  static DONOR_DELETE: EnumValue = { code: "0141", name: "DONOR_DELETE" };
  static PATIENT_EDIT: EnumValue = { code: "0151", name: "PATIENT_EDIT" };
  static PATIENT_DELETE: EnumValue = { code: "0161", name: "PATIENT_DELETE" };
  static DONOR_NEW: EnumValue = { code: "0171", name: "DONOR_NEW" };
  static PATIENT_NEW: EnumValue = { code: "0181", name: "PATIENT_NEW" };
  static DATABASE_VALUES_COMMANDS: EnumValue = {
    code: "0191",
    name: "DATABASE_VALUES_COMMANDS",
  };
  static DONORS_HISTORY_OF_CHANGES: EnumValue = {
    code: "0201",
    name: "DONORS_HISTORY_OF_CHANGES",
  };
  static DONORS_EVENTS: EnumValue = { code: "0211", name: "DONORS_EVENTS" };
  static IMPORTS: EnumValue = { code: "0221", name: "IMPORTS" };
  static EXPORTS: EnumValue = { code: "0231", name: "EXPORTS" };
  static PATIENSS_HISTORY_OF_CHANGES: EnumValue = {
    code: "0241",
    name: "PATIENSS_HISTORY_OF_CHANGES",
  };
  static PATIENTS_EVENTS: EnumValue = { code: "0251", name: "PATIENTS_EVENTS" };
  static DONOR_EDIT_SAVE: EnumValue = { code: "0261", name: "DONOR_EDIT_SAVE" };
  static PATIENT_EDIT_SAVE: EnumValue = {
    code: "0271",
    name: "PATIENT_EDIT_SAVE",
  };
  static DONOR_NON_HLA: EnumValue = { code: "0281", name: "DONOR_NON_HLA" };
  static PATIENT_NON_HLA: EnumValue = { code: "0291", name: "PATIENT_NON_HLA" };
  static DONOR_HLA: EnumValue = { code: "0301", name: "DONOR_HLA" };
  static ANALYTIC_TOOL: EnumValue = { code: "0311", name: "ANALYTIC_TOOL" };
  static STORE_ACCESS: EnumValue = { code: "0321", name: "STORE_ACCESS" };
  static STORE_WORK_WITH_PRODUCTS: EnumValue = {
    code: "0331",
    name: "STORE_WORK_WITH_PRODUCTS",
  };
  static STORE_EDITING_STRUCTURE: EnumValue = {
    code: "0341",
    name: "STORE_EDITING_STRUCTURE",
  };
  static INTERFACES_INVOICES: EnumValue = {
    code: "0351",
    name: "INTERFACES_INVOICES",
  };
  static BMD_PAT_TO_EMD_PAT_CONVERSION: EnumValue = {
    code: "0361",
    name: "BMD_PAT_TO_EMD_PAT_CONVERSION",
  };
  static REQUESTS_WITHOUT_ANSWERS: EnumValue = {
    code: "0371",
    name: "REQUESTS_WITHOUT_ANSWERS",
  };
  static NOT_USED: EnumValue = { code: "0381", name: "NOT_USED" };
  static DATA_VALIDATOR: EnumValue = { code: "0391", name: "DATA_VALIDATOR" };
  static INVOICES: EnumValue = { code: "0401", name: "INVOICES" };
  static LOCAL_DONOR_HLA_MODIFY: EnumValue = {
    code: "0411",
    name: "LOCAL_DONOR_HLA_MODIFY",
  };
  static ATA_ADMIN: EnumValue = { code: "0501", name: "ATA_ADMIN" };
  static ATA_REGISTRY: EnumValue = { code: "0511", name: "ATA_REGISTRY" };
  static ATA_USER: EnumValue = { code: "0521", name: "ATA_USER" };
  static AUTO_BMDW_EXPORT: EnumValue = {
    code: "0641",
    name: "  AUTO_BMDW_EXPORT",
  };
}

export default AccessRights;
/*
001-DonorCommands (0-denied, 1-available) 
002-DonorReports 
003-DonorStack
004-DonorAnalyticTool 
005-PatientCommands 
006-PatientReports 
007-PatientStack
008-PatientAnalyticTool 
009-SearchCommands
010-EMDISCommands
011-EditionCommands
012-DatabaseAdmin
013-DonorEdit
014-DonorDelete
015-PatientEdit
016-PatientDelete
017-DonorNew
018-PatientNew
019-Database Values Commands
020-Donor’s history of changes
021-Donor’s events
022-Imports
023-Exports
024-Patient’s history of changes
025-Patient’s events
026-Donor edit (edit button visible, can’t save changes)
027-Patient edit (edit button visible, can’t save changes)
028-Donor non-HLA data modify
029-Patient non-HLA data modify
030-Donor HLA modify
031-Analytic tool exports
032-Store access
033-Store, work with products
034-Store, editing structure
035-Interfaces, Invoices
036-BMDpat to EMDpat conversion
037-Requests without answers
038-BE Single Managed
039-Data validator
040-Invoices
041-localDonor HLA modify
042-TC client – obsolete, not used
043-Laboratory connect
044-Hide persnumber
045-DeCentralized Req
046-Samples
047-FollowUp
048-Relationships
049-QualityControl
050-FollowUp Analytic Tool
051-Search determ. control
052-User config. Editor
053-CT All
054-Scheduler
055-Statistics
056-HLA tool box
057-Template Manager
058-DB Maintance
059-Hide Donor Name
060-Hide Donor HLA
061-Hide Patient HLA
062-Hide MsgDen
063-Emdis Communication – obsolete, not used
064-Auto WMDA export
065-Hide Local Request Result
066-Hide Emdis Request Result
067-Hide Patient Name
068-Show Exceptions
069-Show Btn EmdMsg Search 
070-Show System Message
071-Show Matching Pref 
072-Patient Update HLA 
073-Is Workup Controller
  */
